import React from "react"
import { Flex, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Link from "@common/Link"

export const bgImage = graphql`
  fragment bgImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      event1: file(
        relativePath: { eq: "events/austin-distel-rxpThOwuVgE-unsplash.png" }
      ) {
        ...bgImage
      }
      event2: file(
        relativePath: { eq: "events/neonbrand-1-aA2Fadydc-unsplash.png" }
      ) {
        ...bgImage
      }
    }
  `)

  return (
    <Flex variant="layout.subnav">
      <BackgroundImage fluid={data.event1.childImageSharp.fluid}>
        <Flex variant="layout.events.item">
          <Styled.h4>Java Workshops, Kraków</Styled.h4>
          <Styled.p>09.04.2020, Hevre, Kraków</Styled.p>
          <Link to="/event" variant="subnav">
            Zobacz
          </Link>
        </Flex>
      </BackgroundImage>
      <BackgroundImage fluid={data.event2.childImageSharp.fluid}>
        <Flex variant="layout.events.item">
          <Styled.h4>Node.js Workshops, Warszawa</Styled.h4>
          <Styled.p>19.04.2020, Torwar, Warszawa</Styled.p>
          <Link to="/event" variant="subnav">
            Zobacz
          </Link>
        </Flex>
      </BackgroundImage>
    </Flex>
  )
}

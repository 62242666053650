import React, { useEffect, useState, useRef } from "react"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby" // to query for image data
import Img from "gatsby-image" // to take image data and render it
import { Box, Container, Flex, Styled } from "theme-ui"

import { colorModes, contentSliderProps, imgSliderProps } from "@const"

import Layout from "@layouts/default"

import ArrowBtn from "@components/ArrowBtn"

import Link from "@common/Link"
import SEO from "@components/seo"

import "slick-carousel/slick/slick.css"

import EventsList from "@components/EventsList"

export default () => {
  const [state, setState] = useState({ nav1: null, nav2: null })
  const slider1 = useRef()
  const slider2 = useRef()

  const sliderImgData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "slides/home" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])

  const { nav1, nav2 } = state

  return (
    <Layout>
      <SEO title="Home" />
      <Flex as="section" variant="layout.events.container">
        <Box variant="layout.imgSliderContainer">
          <Slider
            asNavFor={nav2}
            ref={slider => (slider1.current = slider)}
            {...imgSliderProps}
          >
            {sliderImgData.allFile.edges.map(image => {
              return (
                <Box variant="layout.homeImgSlide" key={image.node.base}>
                  <Img
                    fluid={image.node.childImageSharp.fluid}
                    style={{ height: `960px` }}
                    alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
                  />
                </Box>
              )
            })}
          </Slider>
        </Box>

        <Container variant="layout.flexColumn">
          <Flex variant="layout.contentSliderContainer">
            <Slider
              asNavFor={nav1}
              ref={slider => (slider2.current = slider)}
              {...contentSliderProps}
            >
              <Flex variant="layout.events.contentSlide">
                <Styled.h1>Conference</Styled.h1>
                <Styled.h3>IT Conference w Szczecinie</Styled.h3>
                <Styled.p>09.05.2020, Netto Arena, Szczecin</Styled.p>
                <Link to="/calendar">
                  <Flex variant="layout.events.contentSlide.link">
                    <ArrowBtn colorMode={colorModes.dark}/>
                    zobacz szczegóły
                  </Flex>
                </Link>
              </Flex>
              <Flex variant="layout.events.contentSlide">
                <Styled.h1>Conference</Styled.h1>
                <Styled.h3>IT Conference w Szczecinie</Styled.h3>
                <Styled.p>09.05.2020, Netto Arena, Szczecin</Styled.p>
                <Link to="/calendar">
                  <Flex variant="layout.events.contentSlide.link">
                    <ArrowBtn colorMode={colorModes.dark} />
                    zobacz szczegóły
                  </Flex>
                </Link>
              </Flex>
              <Flex variant="layout.events.contentSlide">
                <Styled.h1>Conference</Styled.h1>
                <Styled.h3>IT Conference w Szczecinie</Styled.h3>
                <Styled.p>09.05.2020, Netto Arena, Szczecin</Styled.p>
                <Link to="/calendar">
                  <Flex variant="layout.events.contentSlide.link">
                    <ArrowBtn colorMode={colorModes.dark} />
                    zobacz szczegóły
                  </Flex>
                </Link>
              </Flex>
            </Slider>
          </Flex>
        </Container>
      </Flex>

      <Container as="section">
        <EventsList />
      </Container>
    </Layout>
  )
}
